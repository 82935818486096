<template>
  <div>
    <section class="section-navbar sticky-top">
      <Navbar />
    </section>
    <div class="about-us">
      <div class="container">
        <header data-aos="fade-in">
          <h1 class="titulo-productos text-center">Preguntas Frecuentes</h1>
        </header>
        <div class="row">
          <div class="col-12">
            <FaqAccordion :faq="faqGeneral" data-aos="fade-in" data-aos-delay="100" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <FaqAccordion :faq="faqTintoreria"  data-aos="fade-in" data-aos-delay="200" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <FaqAccordion :faq="faqLavanderia"  data-aos="fade-in" data-aos-delay="300" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/navbar/index.vue';
import FaqAccordion from '@/components/faq/accordion/accordion.vue';

export default {
  name: 'Preguntas Frecuentes',
  data() {
    const faqDB = {
      faqGeneral: {
        id: 'faq-general',
        title: 'General',
        questions: [
          {
            question: '¿Qué es Wapidu?',
            answer: `
              <div>
                <p>
                  Es la primera plataforma en el mundo que une a los mejores proveedores de
                  servicios en el cuidado de prendas con tus necesidades. Devolviéndote tiempo para
                  hacer lo que amas y calidad de vida. Convierte tu espacio de lavado en tu Home
                  Office.
                </p>
              </div>
            `,
          },
        ],
      },
      faqTintoreria: {
        id: 'faq-tintoreria',
        title: 'Suscripción Tintorería',
        questions: [
          {
            question: '¿Qué es la Suscripción Wapidu?',
            answer: `
              <div>
                <p>
                  Es tener programado para siempre tu servicio de recolección y entrega con solo
                  hacerlo una vez. Es lucir impecable todos los días, es olvidarte de desmanchar,
                  lavar y planchar. Es aprovechar el tiempo en lo que más amas, es calidad de vida.
                </p>
              </div>
            `,
          },
          {
            question: '¿En qué consiste la Suscripción Wapidu?',
            answer: `
              <div>
                <p>
                  En asegurar que por un precio preferente cada semana puedas gozar de un servicio
                  impecable en el cuidado de tus prendas directamente en el domicilio que elijas.
                </p>
              </div>
            `,
          },
          {
            question: '¿Cada cuánto se debe pagar la Suscripción Wapidu?',
            answer: `
              <div>
                <p>Es un cargo que se realiza en la tarjeta que elijas cada 28 días.</p>
              </div>
            `,
          },
          {
            question: '¿Cómo elegir la suscripción correcta?',
            answer: `
              <div>
                <p>La podrás escoger dependiendo del número de miembros de tu familia</p>
                <ul>
                  <li>Small WapiBag: 1 o 2 personas</li>
                  <li>Medium WapiBag: 3 a 4 personas</li>
                  <li>Large WapiBag: 5 a 6 personas</li>
                </ul>
              </div>
            `,
          },
          {
            question: '¿Qué puedo meter en la WapiBag?',
            answer: `
              <div>
                <p>No procesamos prendas íntimas.</p>
                <p>
                  No aplica Piel, cortinas, ni otros servicios especializados como: Empaque al alto
                  vacío, teñido, arreglos o composturas, etc.
                </p>
              </div>
            `,
          },
          {
            question: '¿Debo de darle propina al WapiValet?',
            answer: `
              <div>
                <p>No es obligatoria. La única propina será tu sonrisa.</p>
              </div>
            `,
          },
          {
            question: '¿Se requiere separar las prendas según el tipo de servicio?',
            answer: `
              <div>
                <p>No se requiere, a menos que se requiera algún servicio especial.</p>
              </div>
            `,
          },
          {
            question: '¿Puedo cambiar el plan de Suscripción Wapidu cuando lo desee?',
            answer: `
              <div>
                <p>
                  Sí, el cambio de suscripción que se cambie aplicará a partir de la renovación de
                  los 28 días.
                </p>
              </div>
            `,
          },
          {
            question: '¿Cuándo se renueva mi Suscripción Wapidu?',
            answer: `
              <div>
                <p>Tú suscripción se renueva automáticamente cada 28 días.</p>
              </div>
            `,
          },
          {
            question: '¿Puedo solicitar servicios extra, adicionales a la Suscripción Wapidu?',
            answer: `
              <div>
                <p>
                  Claro, cualquier servicio adicional tendrá precios preferenciales por ser
                  suscriptor Wapidu.
                </p>
              </div>
            `,
          },
          {
            question: '¿El costo de la WapiBag está incluida en la Suscripción Wapidu',
            answer: `
              <div>
                <p>Sí, en la suscripción mínima de 6 meses.</p>
                <p>
                  En caso de daño o extravío antes de los 6 meses el bolso será cobrado nuevamente.
                </p>
              </div>
            `,
          },

          {
            question: '¿Puedo cancelar mi suscripción?',
            answer: `
              <div>
                <p>
                  Es muy fácil cancelar o reactivar tu suscripción. Puedes hacerlo directamente en
                  la plataforma al menos 24 horas antes de la renovación.
                </p>
              </div>
            `,
          },

          {
            question: '¿Tiene algún cargo la cancelación de la Suscripción Wapidu?',
            answer: `
              <div>
                <p>En caso de cancelación previa a 6 meses tiene un cargo</p>
                <ul>
                  <li>Small WapiBag $ 88</li>
                  <li>Medium WapiBag $ 90</li>
                  <li>Large WapiBag $ 99</li>
                </ul>
              </div>
            `,
          },

          {
            question: '¿Puedo entregar las prendas en mi servicio semanal fuera de la WapiBag?',
            answer: `
              <div>
                <p>
                  No, es importante entregarlas en la WapiBag, pero no te preocupes, con confianza
                  solicita al WapiValet una nueva WapiBag con su costo correspondiente y con gusto
                  recibiremos tu pedido.
                </p>
                <p>En caso de tener servicios adicionales, también se podrán recibir.</p>
              </div>
            `,
          },

          {
            question: '¿Cómo pago mi Suscripción Wapidu?',
            answer: `
              <div>
                <p>
                  Es muy fácil, la suscripción se paga a través de app de Wapidu en 4 sencillos
                  pasos:
                </p>
                <ol>
                  <li>Datos de alta</li>
                  <li>Elegir tipo de suscripción</li>
                  <li>Dar de alta tu forma de pago</li>
                  <li>
                    Estas listo para elegir días y horarios que mejor te convengan para disfrutar de
                    la Experiencia Wapidu.
                  </li>
                </ol>
              </div>
            `,
          },

          {
            question:
              '¿Si no cabe en mi WapiBag un peluche, almohada, edredón o alguna otra prenda de gran tamaño?',
            answer: `
              <div>
                <p>
                  No te preocupes, recuerda que un beneficio de ser Suscriptor Wapidu, es que tienes
                  precios especiales en todos los servicios que excedan el tamaño de tu WapiBag.
                </p>
              </div>
            `,
          },
          {
            question: '¿Puedo pausar mi Suscripción Wapidu en caso de viaje?',
            answer: `
              <div>
                <p>
                  Sí, elige en la app de Wapidu pausar la suscripción al menos 24 horas antes de la
                  fecha programada y cuanto estés listo puedes activarla nuevamente.
                </p>
              </div>
            `,
          },
        ],
      },
      faqLavanderia: {
        id: 'faq-lavanderia',
        title: 'Suscripción Lavandería',
        questions: [
          {
            question: '¿Qué es la suscripción Wapidu Lavandería?',
            answer: `
              <div>
                <p>
                  Es tener programado para siempre tu servicio de recolección y entrega con solo
                  hacerlo una vez. Es lucir impecable todos los días, es olvidarte de Lavar, Secar y
                  Doblar. Es aprovechar el tiempo en lo que más amas, es calidad de vida.
                </p>
              </div>
            `,
          },
          {
            question: '¿En qué consiste la Suscripción Wapidu Lavandería?',
            answer: `
              <div>
                <p>
                  En asegurar que por un precio preferente cada semana puedas gozar de un servicio
                  impecable en el cuidado de tus prendas directamente en el domicilio que elijas.
                </p>
              </div>
            `,
          },
          {
            question: '¿Cada cuánto se debe pagar la Suscripción Wapidu lavandería?',
            answer: `
              <div>
                <p>Es un cargo que se realiza en la tarjeta que elijas cada 28 días.</p>
              </div>
            `,
          },

          {
            question: '¿Cómo elegir la Suscripción Wapidu correcta?',
            answer: `
              <div>
                <p>La podrás escoger dependiendo del número de miembros de tu familia</p>
                <ul>
                  <li>Small Family EcoBag: 1 o 2 personas con un costo $550</li>
                  <li>Big Family EcoBag: 3 a 4 personas con un costo $800</li>
                </ul>
              </div>
            `,
          },
          {
            question: '¿Qué puedo meter en mi EcoBag?',
            answer: `
              <div>
                <p>Cualquier tipo de prenda de lavandería.</p>
                <p>
                  Ejemplo: camisa, playera, pijama, bermuda, playera, sábana, toalla, todo aquello
                  que requiere ser lavado, excepto ropa íntima. Olvídate ya de la lavadora de tu
                  casa. ¡Wapidu lo hace por ti!
                </p>
              </div>
            `,
          },

          {
            question: '¿Qué capacidad tiene cada Suscripción Wapidu de Lavandería?',
            answer: `
              <div>
                <p>
                  Puedes seleccionar una de las dos suscripciones, la que mejor se adapte a tus
                  necesidades:
                </p>
                <p>
                  Small Family: Servicio de Lavandería, Lavado, Secado y Doblado, para 1 o 2
                  personas. La capacidad del EcoBag está calculada entre 4 y 5 Kg aproximadamente,
                  en prendas equivalen entre 12 y 20 prendas, debes tener en cuenta el volumen de
                  cada pieza. Las dimensiones del EcoBag son 50 cm. Alto X 60 cm. Ancho. Una vez
                  realizada la suscripción, agendas en calendario tus citas, nuestro WapiValet, en
                  el primer servicio, te hará entrega de 4 EcoBag, donde semanalmente enviaras tu
                  ropa. El Servicio a Domicilio está Incluido.
                </p>
                <p>
                  Big Family: Servicio de Lavandería, Lavado, Secado y Doblado, para 3 o 4 personas.
                  La capacidad del EcoBag está calculada entre 8 y 10 Kg aproximadamente, en prendas
                  equivalen entre 35 y 40 prendas, tener en cuenta el volumen de cada pieza. Las
                  dimensiones del EcoBag son 83 cm Alto X 60 cm. Ancho. Una vez realizada la
                  suscripción, agendas en calendario tus citas, nuestro WapiValet, en el primer
                  servicio, te hará entrega de 4 EcoBags, donde semanalmente enviaras tu ropa. El
                  Servicio a Domicilio está Incluido.
                </p>
              </div>
            `,
          },
          {
            question: '¿Qué no puedo meter en mi EcoBag?',
            answer: `
              <div>
                <p>No procesamos prendas íntimas</p>
                <p>
                  No aplica Piel, cortina, edredón, ni otros servicios especializados como: Empaque
                  al alto vacío, teñido, arreglos o composturas, etc.
                </p>
              </div>
            `,
          },
          {
            question: '¿Debo de darle propina al WapiValet?',
            answer: `
              <div>
                <p>No es obligatoria. La única propina será tu sonrisa.</p>
              </div>
            `,
          },
          {
            question: '¿Se requiere separar las prendas según el tipo de servicio?',
            answer: `
              <div>
                <p>No se requiere, a menos que se solicites algún servicio especial.</p>
              </div>
            `,
          },
          {
            question: '¿Puedo cambiar el plan de Suscripción Wapidu cuando lo desee?',
            answer: `
              <div>
                <p>
                  Sí, el cambio de suscripción que se cambie aplicará a partir de la renovación de
                  los 28 días.
                </p>
              </div>
            `,
          },
          {
            question: '¿Cuándo se renueva mi Suscripción Wapidu?',
            answer: `
              <div>
                <p>Tú suscripción se renueva automáticamente cada 28 días.</p>
              </div>
            `,
          },

          {
            question: '¿Puedo solicitar servicios extra, adicionales a la Suscripción Wapidu?',
            answer: `
              <div>
                <p>
                  Claro, cualquier servicio adicional tendrá precios preferenciales por ser cliente
                  Wapidu.
                </p>
              </div>
            `,
          },
          {
            question: '¿El costo del EcoBag está incluida en la Suscripción Wapidu?',
            answer: `
              <div>
                <p>
                  Sí, en la suscripción se contempla entregar 4 EcoBags, una para cada servicio,
                  durante los 28 días de la suscripción.
                </p>
              </div>
            `,
          },
          {
            question: '¿Puedo cancelar mi Suscripción Wapidu?',
            answer: `
              <div>
                <p>
                  Es muy fácil cancelar o reactivar tu suscripción. Puedes hacerlo directamente en
                  la plataforma al menos 24 horas antes de la renovación.
                </p>
              </div>
            `,
          },
          {
            question: '¿Puedo entregar las prendas en mi servicio semanal fuera del EcoBag?',
            answer: `
              <div>
                <p>
                  No, es importante entregarlas en la EcoBag, pero no te preocupes, con confianza
                  solicita al WapiValet una nueva EcoBag con su costo correspondiente y con gusto
                  recibiremos tu pedido. Costo EcoBag adicional de la suscripción Small Family $138,
                  Big Family $ 200.
                </p>
                <p>
                  En caso de tener servicios adicionales, si se podrán recibir de cualquier forma.
                </p>
              </div>
            `,
          },

          {
            question: '¿Cómo pago mi Suscripción Wapidu lavandería?',
            answer: `
              <div>
                <p>
                  Es muy fácil, la suscripción se paga a través de la app de Wapidu en 4 sencillos
                  pasos:
                </p>
                <ol>
                  <li>Datos de alta</li>
                  <li>Elegir tipo de suscripción</li>
                  <li>Dar de alta tu forma de pago</li>
                  <li>
                    Con esto, estás listo para elegir días y horarios que mejor te convengan para
                    disfrutar de la Experiencia Wapidu.
                  </li>
                </ol>
              </div>
            `,
          },
          {
            question:
              '¿Si no cabe en mi EcoBag un peluche, almohada, edredón o alguna otra prenda de gran tamaño?',
            answer: `
              <div>
                <p>
                  No te preocupes, recuerda que un beneficio de ser cliente Wapidu, es que tienes
                  precios especiales en todos los servicios que excedan el tamaño de tu EcoBag.
                </p>
              </div>
            `,
          },
          {
            question: '¿Puedo pausar mi Suscripción Wapidu en caso de viaje?',
            answer: `
              <div>
                <p>
                  Sí, elige en la app de Wapidu pausar la suscripción al menos 24 horas antes de la
                  fecha programada y cuando estés listo puedes activarla nuevamente.
                </p>
              </div>
            `,
          },
        ],
      },
    };
    /*
    Object.keys(faqDB).map((faqDBKey) => {
      faqDB[faqDBKey].questions = faqDB[faqDBKey].questions.map((question) => {
        return {
          ...question,
        };
      });
    });
    */
    return faqDB;
  },
  components: {
    Navbar,
    FaqAccordion,
  },
};
</script>
<style scoped></style>
