<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-12">
        <h3 class="mt-0 mb-3">{{ faq.title }}</h3>
        <div class="card mt-0 mb-5 p-4" :id="faq.id">
          <div
            class="mb-2"
            v-for="(question, index) in faq.questions"
            :key="'question-' + index"
            :data-aos-anchor="'#' + faq.id"
            data-aos="fade-in"
            :data-aos-delay="100 + index * 100"
          >
            <h4 class="mt-0 mb-2">{{ question.question }}</h4>
            <div class="mt-0 mb-2" v-html="question.answer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqAccordion',

  data({ $attrs }) {
    return {
      faq: $attrs.faq,
    };
  },
  mounted() {},
  methods: {},
  created() {},
  watch: {},

  components: {},
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
</style>
